.slick-dots{
    display: inline-block !important;
    text-align:left;
    float:left;
    position:relative;
    width:auto;
    bottom:0;
    li{
        &:not(:last-child){
            margin-right:15px;
        }
        &.slick-active{
            button{
                span.slick-dot-text{
                    opacity:1;
                }
            }
        }
        button{
            width:30px;
            height:30px;
            padding:5px;
            border-bottom:3px solid $brand-color;
            &:before{
                display:none;
            }
            span.slick-dot-text{
                font-size:1rem;
                color:#000;
                opacity:0;
            }

        }
    }
}
.slick-slide:focus { outline: none; } 