.bg-image{

  background-repeat: no-repeat;
  background-position: center;

  &--cover{
    -webkit-background-size: cover;
    background-size: cover;
  }

}

// .bg-primary{

//   background-color: $brand-primary;
//   color: #ffffff;

// }

// .bg-gray{

//   background-color: $brand-gray-light;

// }

@media ( max-width: $screen-xs-max ){

  .bg-image{

    &--none-xs{

      background-image: none !important;

    }

  }

}