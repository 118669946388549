.row{

  &.row-vertical{

    @include row-vertical-variant( 30px );

  }
}


.row-10{

  @include row-variant( 10px );

  &.row-vertical{

    @include row-vertical-variant( 10px );

  }


}

.row-20{

  @include row-variant( 20px );

  &.row-vertical{

    @include row-vertical-variant( 20px );

  }

}