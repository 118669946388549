.overlay{

  position: relative;

  &:before{

    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

  }

  &--black{

    &:before{

      background: rgba( #000000, 0.4 );

    }

  }

}