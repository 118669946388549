// banner css
.banner{
	&-slide{
		max-width:46rem;
		padding:$baseline-rem*6 0 ;
		margin-left:$baseline-rem*2;
		&__sub-heading{
			font-family:$font-family-one;
			font-weight:400;
			font-size:18px;
			span{
				color:$brand-color;
				font-family:$font-family-one;
				font-weight:400;
				font-size:18px;
			}
		}
		&__heading{
			margin-top:0;
			text-transform:uppercase;
		}
	}
}
@media (max-width: $screen-xs-max) {
	.banner{
		&-slide{

			padding:$baseline-rem*3 0;
			margin-left: 0;

		}
	}

}