.thumbnail{
	border:0;
	&-icon{
		border:1px solid #e1e1e1;
		text-align:center;
		padding:2rem 1rem;
		margin-top:$baseline-rem*1;
		border-radius: 0;
		img{
			max-width:$baseline-rem*6;
			margin:0 auto;
			display:block;
			margin-bottom:$baseline-rem*2;
		}

		p{
			margin-bottom:0;
			font-size:12px;
		}
		&__title{
			text-transform:uppercase;
			margin-top:0;
		}
	}
	&-img{
		&__large{
			width:50%;
			height:$baseline-rem*25;
		}
		@extend .bg-image;
		background-size:cover;
		display:block;
		float:left;
		position:relative;
		overflow:hidden;
		&:hover{
			.thumbnail-img__caption{
				bottom:0;
			}
		}
		&__caption{
			position:absolute;
			z-index:99;
			padding-bottom: $baseline-rem*2;
			bottom:-50px;
			left:$baseline-rem*1;
			-webkit-transition: bottom .5s;
			transition: bottom .5s;
		}
		&__heading{
			color:#fff;
			margin-top: 0;
			margin-bottom:$baseline-rem*1;
			text-transform:uppercase;
		}
		&__sub-heading{
			color:#fff;
		}
	}
	&-icon-lg{
		text-align:center;
		padding:$baseline-rem*2 $baseline-rem*1 $baseline-rem*1 $baseline-rem*1;
		margin-top:$baseline-rem*3;
		border:1px solid #e1e1e1;
		border-radius: 0;
		img{
			max-width:50px;
			margin:0 auto;
			display:block;
		}
		.heading{
			font-family:'Montserrat';
			font-weight:700;
			font-size:$font-size-h4;
			margin:$baseline-rem*1 0;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.thumbnail{
		&-img{
			&__large{
				width:100%!important;
				float:none!important;
			}
		}
	}
}
