/**
 * =====================
 * Padding
 * =====================
 */
@include incremental-variant( p, ( padding ) );
@include incremental-variant( p-t, ( padding-top ) );
@include incremental-variant( p-r, ( padding-right ) );
@include incremental-variant( p-b, ( padding-bottom ) );
@include incremental-variant( p-l, ( padding-left ) );
@include incremental-variant( p-v, ( padding-top, padding-bottom ) );
@include incremental-variant( p-h, ( padding-left, padding-right ) );

/**
 * =====================
 * Margin
 * =====================
 */

@include incremental-variant( m, ( margin ) );
@include incremental-variant( m-v, ( margin-top, margin-bottom ) );
@include incremental-variant( m-h, ( margin-left, margin-right ) );
@include incremental-variant( m-t, ( margin-top ) );
@include incremental-variant( m-r, ( margin-top ) );
@include incremental-variant( m-b, ( margin-bottom ) );
@include incremental-variant( m-l, ( margin-left ) );

.m-out{

  margin-left: (-1) * $grid-gutter-width;
  margin-right: (-1) * $grid-gutter-width;

}

.no-margin{
  margin: 0;
}




@media ( min-width: $screen-lg-min ){

  /**
 * =====================
 * Padding
 * =====================
 */
  @include incremental-variant( p-lg, ( padding ) );

  @include incremental-variant( p-v-lg, ( padding-top, padding-bottom ) );

  @include incremental-variant( p-h-lg, ( padding-left, padding-right ) );

  @include incremental-variant( p-l-lg, ( padding-left ) );

  @include incremental-variant( p-r-lg, ( padding-right ) );

  @include incremental-variant( p-b-lg, ( padding-bottom ) );

  @include incremental-variant( p-t-lg, ( padding-top ) );


  /**
   * =====================
   * Margin
   * =====================
   */

  @include incremental-variant( m-lg, ( margin ) );

  @include incremental-variant( m-v-lg, ( margin-top, margin-bottom ) );

  @include incremental-variant( m-t-lg, ( margin-top ) );

  @include incremental-variant( m-b-lg, ( margin-bottom ) );

  @include incremental-variant( m-l-lg, ( margin-left ) );

  @include incremental-variant( m-r-lg, ( margin-right ) );

  .m-out-lg{

    margin-left: (-1) * $grid-gutter-width;
    margin-right: (-1) * $grid-gutter-width;

  }

  .no-margin-lg{
    margin: 0;
  }

  .m-h-auto-lg{

    margin-left: auto;
    margin-right: auto;

  }

  .m-v-auto-lg{

    margin-top: auto;
    margin-bottom: auto;

  }


}

@media ( min-width: $screen-md-min ) and ( max-width: $screen-md-max ){

  /**
 * =====================
 * Padding
 * =====================
 */
  @include incremental-variant( p-md, ( padding ) );

  @include incremental-variant( p-v-md, ( padding-top, padding-bottom ) );

  @include incremental-variant( p-h-md, ( padding-left, padding-right ) );

  @include incremental-variant( p-l-md, ( padding-left ) );

  @include incremental-variant( p-r-md, ( padding-right ) );

  @include incremental-variant( p-b-md, ( padding-bottom ) );

  @include incremental-variant( p-t-md, ( padding-top ) );


  /**
   * =====================
   * Margin
   * =====================
   */

  @include incremental-variant( m-md, ( margin ) );

  @include incremental-variant( m-v-md, ( margin-top, margin-bottom ) );

  @include incremental-variant( m-t-md, ( margin-top ) );

  @include incremental-variant( m-b-md, ( margin-bottom ) );

  @include incremental-variant( m-l-md, ( margin-left ) );

  @include incremental-variant( m-r-md, ( margin-right ) );

  .m-out-md{

    margin-left: (-1) * $grid-gutter-width;
    margin-right: (-1) * $grid-gutter-width;

  }

  .no-margin-md{
    margin: 0;
  }


  .m-out-md{

    margin-left: (-1) * $grid-gutter-width;
    margin-right: (-1) * $grid-gutter-width;

  }

  .m-h-auto-md{

    margin-left: auto;
    margin-right: auto;

  }

  .m-v-auto-md{

    margin-top: auto;
    margin-bottom: auto;

  }


}

@media ( min-width: $screen-sm-min ) and ( max-width: $screen-sm-max ){

  /**
 * =====================
 * Padding
 * =====================
 */
  @include incremental-variant( p-sm, ( padding ) );

  @include incremental-variant( p-v-sm, ( padding-top, padding-bottom ) );

  @include incremental-variant( p-h-sm, ( padding-left, padding-right ) );

  @include incremental-variant( p-l-sm, ( padding-left ) );

  @include incremental-variant( p-r-sm, ( padding-right ) );

  @include incremental-variant( p-b-sm, ( padding-bottom ) );

  @include incremental-variant( p-t-sm, ( padding-top ) );


  /**
   * =====================
   * Margin
   * =====================
   */

  @include incremental-variant( m-sm, ( margin ) );

  @include incremental-variant( m-v-sm, ( margin-top, margin-bottom ) );

  @include incremental-variant( m-t-sm, ( margin-top ) );

  @include incremental-variant( m-b-sm, ( margin-bottom ) );

  @include incremental-variant( m-l-sm, ( margin-left ) );

  @include incremental-variant( m-r-sm, ( margin-right ) );

  .m-out-sm{

    margin-left: (-1) * $grid-gutter-width;
    margin-right: (-1) * $grid-gutter-width;

  }

  .no-margin-sm{
    margin: 0;
  }

  .m-h-auto-sm{

    margin-left: auto;
    margin-right: auto;

  }

  .m-v-auto-sm{

    margin-top: auto;
    margin-bottom: auto;

  }

}

@media ( max-width: $screen-xs-max ){

  /**
 * =====================
 * Padding
 * =====================
 */
  @include incremental-variant( p-xs, ( padding ) );

  @include incremental-variant( p-v-xs, ( padding-top, padding-bottom ) );

  @include incremental-variant( p-h-xs, ( padding-left, padding-right ) );

  @include incremental-variant( p-l-xs, ( padding-left ) );

  @include incremental-variant( p-r-xs, ( padding-right ) );

  @include incremental-variant( p-b-xs, ( padding-bottom ) );

  @include incremental-variant( p-t-xs, ( padding-top ) );


  /**
   * =====================
   * Margin
   * =====================
   */

  @include incremental-variant( m-xs, ( margin ) );

  @include incremental-variant( m-v-xs, ( margin-top, margin-bottom ) );

  @include incremental-variant( m-t-xs, ( margin-top ) );

  @include incremental-variant( m-b-xs, ( margin-bottom ) );

  @include incremental-variant( m-l-xs, ( margin-left ) );

  @include incremental-variant( m-r-xs, ( margin-right ) );

  .m-out-xs{

    margin-left: (-1) * $grid-gutter-width;
    margin-right: (-1) * $grid-gutter-width;

  }

  .no-margin-xs{
    margin: 0;
  }

  .m-h-auto-xs{

    margin-left: auto;
    margin-right: auto;

  }

  .m-v-auto-xs{

    margin-top: auto;
    margin-bottom: auto;

  }

}
