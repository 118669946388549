.isotope{
	&-filters{
		text-align:center;
		margin-bottom:$baseline-rem*2;
		.button{
			background-color:transparent;
			text-transform:uppercase;
			font-weight:700;
			border:3px solid #000;
			color:#000;
			font-size: 12px;
			padding: $baseline-rem*1 $baseline-rem*2;
			&.is-checked,&:hover,&:visited{
				border-color:$brand-color;
				color:$brand-color;
			}
			&:focus{
				outline:0;
				-webkit-box-shadow:none;
				box-shadow:none;
			}
		}
	}
	&-gallery{
		&__item{
			min-width:33%;
		}
		.thumbnail-img{
			width:100%;
			height:$baseline-rem*25;
			margin-top: $baseline-rem*0.3;
		}

	}
}


@media(max-width:$screen-lg-min) and (max-width: $screen-md-max) {
	.isotope{
		&-gallery{
			&__item{
				min-width: 48%;
			}
		}
	}
}

@media (max-width: $screen-sm-max){
	.isotope{

		&-gallery{
			&__item{
				min-width: 100%;
			}
		}
	}
}
@media(max-width: $screen-xs-max){
	.isotope{
		&-filters{
			.button{
				border:2px solid #000;
				font-size: 10px;
				padding: $baseline-rem*0.5 $baseline-rem*1;
				margin-top: 5px;
			}
		}
	}
}