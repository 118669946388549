//homepage content section css
.main-section{
	&__content{
		background-color:#ffffff;
		padding:4rem 3rem;

	}
}
@media (max-width: $screen-xs-max){
	.main-section{
		&__content{
			padding: 2rem 1rem;
		}
	}
}