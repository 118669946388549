@include incremental-variant( mxw, ( max-width ), 5px, 0, 180 );
@include incremental-variant( mxh, ( max-height ) );
@include incremental-variant( mw, ( min-width ), 5px, 0, 180  );
@include incremental-variant( mh, ( min-height ) );

@media ( min-width: $screen-lg-min ){

  @include incremental-variant( mxw-lg, ( max-width ), 5px, 0, 180  );
  @include incremental-variant( mxh-lg, ( max-height ) );
  @include incremental-variant( mw-lg, ( min-width ), 5px, 0, 180  );
  @include incremental-variant( mh-lg, ( min-height ) );

}

@media ( min-width: $screen-md-min ) and ( max-width: $screen-md-max ){

  @include incremental-variant( mxw-md, ( max-width ), 5px, 0, 180  );
  @include incremental-variant( mxh-md, ( max-height ) );
  @include incremental-variant( mw-md, ( min-width ), 5px, 0, 180  );
  @include incremental-variant( mh-md, ( min-height ) );

}

@media ( min-width: $screen-sm-min ) and ( max-width: $screen-sm-max ){

  @include incremental-variant( mxw-sm, ( max-width ), 5px, 0, 180  );
  @include incremental-variant( mxh-sm, ( max-height ) );
  @include incremental-variant( mw-sm, ( min-width ), 5px, 0, 180  );
  @include incremental-variant( mh-sm, ( min-height ) );
}

@media ( max-width: $screen-xs-max ){

  @include incremental-variant( mxw-xs, ( max-width ), 5px, 0, 180  );
  @include incremental-variant( mxh-xs, ( max-height ) );
  @include incremental-variant( mw-xs, ( min-width ), 5px, 0, 180  );
  @include incremental-variant( mh-xs, ( min-height ) );

}