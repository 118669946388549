@mixin incremental-variant( $classPrefix, $attrNames, $multipier: $baseline-rem, $from:0, $to: 10 ){

  @for $i from $from through $to{
    .#{$classPrefix}-#{$i}{
      @each $attrName in $attrNames {
        #{$attrName}: $multipier * $i !important;
      }
    }
  }

}

