.form-control{
	display: inline-block;
	font-size: 13px;
	line-height: normal;
	width: 100%;
	max-width: 100%;
	height: 50px;
	padding: 14px 15px 10px;
	box-shadow: none;
	background-color: #ebebeb;
	background-image: none;
	border-radius: 1px;
	border: 0;
	&:focus{
		outline: none;
		border-color: #000000;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
}
textarea#message{
	height: 150px;
}
span.form-validation{
	color:#ff0000;
	font-size: 12px;
}
