@mixin tab-one-condensed(){

  overflow-x: auto;

  li{

    display: table-cell;

    a{
      white-space: nowrap;
    }

    &.active{

      a{

        &:before{
          display: none;
        }

      }

    }

  }

}