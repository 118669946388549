//$font-family-sans-serif: "Muli", Helvetica, Arial, sans-serif;

$font-size-xs: 14;
$font-size-px-xs: $font-size-xs * 1px;
$font-size-base-xs: 1rem;

//$font-size: 14;
$font-size: 16;
$font-size-px: $font-size * 1px;
$font-size-base: 1rem;
//$font-size-rem: 1rem;

// Make sure to set line height so that line height computed is not decimal
//$line-height-base: 1.857142857142857;
$line-height-base: 1.75;
$line-height-rem: $line-height-base * 1rem;
$line-height-computed:    $line-height-rem;
//$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~26px

$baseline: $line-height-base / 2; //~13px;
$baseline-rem: $baseline * 1rem;

$font-size-large: 1.125rem;
$line-height-large: ( ceil( ( $font-size-large * 1.2 ) / $baseline ) * $baseline );

$font-size-small: 0.875rem;
$line-height-small: ( ceil( ( $font-size-small * 1.2 ) / $baseline ) * $baseline );

$font-size-h1: 2.25rem; //~44px
$font-size-h2: 2rem; // ~34px
$font-size-h3: 1.5rem; // ~28px
$font-size-h4: 1.25rem; // ~18px
$font-size-h5: 1rem;
$font-size-h6: 0.875rem; // ~14px

$headings-font-weight: 700;

// Change the line height numbers of headings here.
$h1-line-height: ( ceil( ( $font-size-h1 * 1 ) / $baseline ) * $baseline );
$h2-line-height: ( ceil( ( $font-size-h2 * 1.2 ) / $baseline ) * $baseline );
$h3-line-height: ( ceil( ( $font-size-h3 * 1.4 ) / $baseline ) * $baseline );
$h4-line-height: ( ceil( ( $font-size-h4 * 1.4 ) / $baseline ) * $baseline );
$h5-line-height: ( ceil( ( $font-size-h5 * 1.4 ) / $baseline ) * $baseline );
$h6-line-height: ( ceil( ( $font-size-h6 * 1.4 ) / $baseline ) * $baseline );

/*$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 9.5%) !default; // #222
$gray-dark:              lighten($gray-base, 13%) !default;   // #333
$gray:                   lighten($gray-base, 20%) !default; // #555
$gray-light:             lighten($gray-base, 60%) !default; // #777
$gray-lighter:           lighten($gray-base, 80%) !default; // #eee

$brand-primary: #3080ee;
$brand-primary-light: lighten( $brand-primary, 10% );
$brand-primary-dark: darken( #3080ee, 5% );
$brand-orange: #f7941d;
$brand-gray-light: #f1f1f1;

$brand-facebook: #3b5998;
$brand-twitter: #1da1f2;
$brand-google-plus: #dd4b39;*/

$border-radius-base: 2px;

$padding-base-vertical: $baseline-rem * 0.5;
$padding-base-horizontal: $baseline-rem * 1.5;

$padding-large-vertical: $baseline-rem * 1.25;
$padding-small-vertical: $baseline-rem * 0.25;

$input-height-base: ($line-height-base + ($padding-base-vertical * 2)) !default;
$input-height-large: (ceil($font-size-large * strip-unit( $line-height-large )) + ($padding-large-vertical * 2));
$input-height-small:  (floor($font-size-small * strip-unit( $line-height-small )) + ($padding-small-vertical * 2));

$navbar-height: $baseline-rem * 3;

$brand-color:#ffa800;
$font-family-one:'Montserrat';
$font-family-two:'Muli';
