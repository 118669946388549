:root{
  font-size: $font-size-px;
}


 //   background-image: linear-gradient( to bottom, #aaaaaa 0, #ffffff 1px );
//   background-repeat: repeat-y;
//   background-size: 100% $baseline-rem;
// }

h1{

  line-height: $h1-line-height;
  margin-top: $baseline-rem * 4;
  margin-bottom: $baseline-rem * 2;

}

h2{

  line-height: $h2-line-height;
  margin-top: $baseline-rem * 4;
  margin-bottom: $baseline-rem * 2;

}

h3{

  line-height: $h3-line-height;
  margin-top: $baseline-rem * 4;
  margin-bottom: $baseline-rem * 2;

}

h4{

  line-height: $h4-line-height;
  margin-top: $baseline-rem * 4;
  margin-bottom: $baseline-rem * 2;

}

h5{

  line-height: $h5-line-height;
  margin-top: $baseline-rem * 4;
  margin-bottom: $baseline-rem * 2;

}

h6{

  line-height: $h6-line-height;
  margin-top: $baseline-rem * 4;
  margin-bottom: $baseline-rem * 2;

}

p{

  margin-top: 0;
  margin-bottom: $baseline-rem * 2;

}

ul, ol{

  margin-top: $baseline-rem;
  margin-bottom: $baseline-rem * 2;

}

blockquote{

  font-style: italic;
  margin: $baseline-rem * 4 0;

}