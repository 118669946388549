.btn {
  font-size: 12px;
  background-color: $brand-color;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  color:#000;
  border:0;
  padding: $baseline-rem*1 $baseline-rem*2;
  border-radius:0;
  transition:0.2s ease;
  &:hover{
    color:#ffffff;
    background-color:#000000;

  }
 
}
.btn-primary:focus{
  background-color: #f15a24;
  outline:none;
}
.btn-primary:visited{
  outline: none;
}
a{
  color:#444444;
}
a:hover, a:focus, a:visited, a:active, .btn-link:hover, .btn-link:focus, .btn-link:visited, .btn-link:active {
	text-decoration: none;
}

.navbar-default{
  background-color: #fcfbfa;
  border:none !important;
  color:#0c0c0c; 
}
/*.form-control{
  border-radius: 0;
}*/
ul{
  list-style: none;
  padding: 0;
}


