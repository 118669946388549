.team{
	position:relative;
	overflow:hidden;
	margin-top:$baseline-rem*1;
	&-info{
		span{
			margin:0;
			color:#000;
			display: block;
			&.name{
				font-weight:700;
				font-size: $font-size-h4;
				margin: $baseline-rem*1 0;
			}
		}

	}
	&-image{
		background: #eeeeee;
		border-radius: 150%;
		width:$baseline-rem*16;
		height: $baseline-rem*16;
		position: relative;
		margin: 0 auto;
		display: block;
		@include transition (ease 0.5s);
		&:hover{
			background: $brand-color;
		}
		img{
			width: $baseline-rem*14;
			height: $baseline-rem*14;
			position: absolute;
			margin: 0 auto;
			display: block;
			top:10px;
			left:15px;
		}
	}
}

