.section-title{
    color:#000;
    font-size:$font-size-h3;
    border-left:5px solid $brand-color;
    font-weight:600;
    text-transform:uppercase;
    margin-top:0;
    padding-left:1rem;
}
.page-title{
    position:relative;
    margin-top:0;
    text-align:center;
    .title{
        margin:0;
    }
    &:after{
    content: '';
    position: absolute;
    border: 2px solid $brand-color;
    width: 120px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-top: 10px;
    }
}