// menubar css
.header{
	height: $baseline-rem *5.5;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background-color:#ffffff;
	@include clearfix();
	-webkit-box-shadow: #ccc 0 0 2px;
    box-shadow: #ccc 0 0 2px;

	&.animate{
		@include transition( top 0.2s );
	}

	&.headroom--unpinned{
		top: -$baseline-rem*5.5;
	}
	&-logo{
		padding-top:$baseline-rem*0.5;
		img{
			max-width: $baseline-rem * 5;
		}

	}
	&-btn{
		.btn{
			padding:1.75rem 2rem;
		}
	}
	&-menubar{
		padding-top: $baseline-rem*2;
		ul{
			li{
				&:not(:last-child){
					margin-right:35px;
				}
				a{
					text-transform: uppercase;
					font-size: 12px;
					letter-spacing: 1px;
					font-weight: 700;
					color:#4d4d4d;
					&:hover{
						color:$brand-color;
					}
				}
				// &.active{
				// 	a{
				// 		color:$brand-color;
				// 	}
				// }
			}
		}
		.main-menu:not(.bs-menu){
			display: none;
		}
	}
	.bs-menu .bs-menu-toggle{
		margin-top: -35px;
	}
}