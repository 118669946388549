@media ( min-width: $screen-lg-min ){

  .center-block-lg{
    margin: 0 auto;
  }

  .text-center-lg{

    text-align: center;

  }

  .text-right-lg{

    text-align: right;

  }

  .pull-left-lg{

    float: left;

  }

  .pull-right-lg{

    float: right;

  }

}

@media ( min-width: $screen-md-min ) and ( max-width: $screen-md-max ){

  .center-block-md{
    margin: 0 auto;
  }

  .text-center-md{

    text-align: center;

  }

  .text-right-md{

    text-align: right;

  }

  .pull-left-md{

    float: left;

  }

  .pull-right-md{

    float: right;

  }

}

@media ( min-width: $screen-sm-min ) and ( max-width: $screen-sm-max ){

  .center-block-sm{
    margin: 0 auto;
  }

  .text-center-sm{

    text-align: center;

  }

  .text-right-sm{

    text-align: right;

  }

  .pull-left-sm{

    float: left;

  }

  .pull-right-sm{

    float: right;

  }

}

@media ( max-width: $screen-xs-max ){

  .center-block-xs{
    margin: 0 auto;
  }


  .text-center-xs{

    text-align: center;

  }

  .text-right-xs{

    text-align: right;

  }

  .pull-left-xs{

    float: left;

  }

  .pull-right-xs{

    float: right;

  }

}