
/* ============================= Montserrat ============================== */
@font-face {
	src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
}
@font-face {
	src: url('../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
}
@font-face {
	src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
}


/* ============================= Montserrat ============================== */
@font-face {
	src: url('../fonts/Muli/Muli-Black.ttf') format('truetype');
	font-family: 'Muli';
	font-style: normal;
	font-weight: 900;
}
@font-face {
	src: url('../fonts/Muli/Muli-Bold.ttf') format('truetype');
	font-family: 'Muli';
	font-style: normal;
	font-weight: 700;
}
@font-face {
	src: url('../fonts/Muli/Muli-Regular.ttf') format('truetype');
	font-family: 'Muli';
	font-style: normal;
	font-weight: 400;
}
@font-face {
	src: url('../fonts/Muli/Muli-SemiBold.ttf') format('truetype');
	font-family: 'Muli';
	font-style: normal;
	font-weight: 600;
}

