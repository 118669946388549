//footer section
.footer{
	padding:$baseline-rem*6 0 $baseline-rem*3 0;
	h5{
		margin-top: 0;
	}
	address{
		font-family: 'Montserrat';
		font-weight: 600;
		font-size: $font-size-h4;
		span{
			font-family: 'Montserrat';
			font-weight: 600;
			font-size:$font-size-h2;
			color:#000;
		}
	}
	&-sociallinks{
		font-size:$font-size-h3;
		margin-bottom: 0;
		a{
			&:hover{
				color:$brand-color;
			}
		}
	}
	&-copyright{
		background-color:#ffffff;
	}
	&-image{
		img{
			display: block;
			margin: 0 auto;
			opacity: 1;
		}
	}
}
@media (max-width: $screen-xs-max) {
	.footer{
		padding:$baseline-rem*2 0 $baseline-rem*1 0;
		address{
			font-size: $font-size-h5;
			span{
				font-size: $font-size-h4;
			}
		}
		&-copyright{
			span{
				font-size: 12px;
			}
		}
	}

}