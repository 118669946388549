.image-grid{
	&.ri-grid{
		ul{
			li{
				a{
					background-color:#EEF2F5;
					-webkit-box-shadow: inset 1px 0px 0px 5px rgba(255,255,255,1);
					-moz-box-shadow: inset 1px 0px 0px 5px rgba(255,255,255,1);
					box-shadow: inset 1px 0px 0px 5px rgba(255,255,255,1);
					background-size:130px!important;
				}
			}
		}
	}
	ul{
		@include clearfix;
		margin-left:-$baseline-rem*0.5;
		margin-right:-$baseline-rem*0.5;
		li{
			width:20%;
			float: left;
			padding-left:$baseline-rem*0.5;
			padding-right:$baseline-rem*0.5;
			background:#EEF2F5;
			.image-grid__item{
				@extend .bg-image;
				background-size:$baseline-rem*8;
				background-color:#EEF2F5;
				height:$baseline-rem*9;
				-webkit-filter: grayscale(100%); 
				-moz-filter: grayscale(100%);
    			filter: grayscale(100%);
    			@include transition(ease 0.5s);
    			&:hover{
    				-webkit-filter: grayscale(0); 
				-moz-filter: grayscale(0);
    			filter: grayscale(0);
    			}

			}
		}
	}
}