@mixin row-variant( $size ){

  $size-half: $size / 2;

  margin-left: -1 * $size-half;
  margin-right: -1 * $size-half;

  [ class^='col-' ],
  [ class*=' col-' ]{
    padding-left: $size-half;
    padding-right: $size-half;
  }


}

@mixin row-vertical-variant( $size ){

  $size-half: $size / 2;

  margin-top: -1 * $size-half;
  margin-bottom: -1 * $size-half;

  [ class^='col-' ],
  [ class*=' col-' ]{
    padding-top: $size-half;
    padding-bottom: $size-half;
  }

}