.text-white{

  color: #ffffff;

}

.text-sm{

  font-size: $font-size-small;
  line-height: $line-height-small;

}

@media ( max-width: $screen-xs-max ){

  .text-h2-xs{

    font-size: $font-size-h3;
    line-height: $font-size-h3;

  }

}