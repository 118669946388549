.page-section{
	&:not(:last-child){
		padding-bottom:$baseline-rem * 7;
	}
}
@media (max-width: $screen-xs-max) {
	.page-section{
		&:not(:last-child){
			padding-bottom: $baseline-rem * 3;
		}
	}
}